<template>
    <router-link class="video-category-part part"
        :to="'/videos/' + $route.params.slug + '?' + urlTypeParam + '=' + linkedCatEnum">
        <div class="thumb-side" v-if="data.templateContentMedia &&
            data.templateContentMedia.overviewImage &&
            data.templateContentMedia.overviewImage !== ''
            ">
            <span class="bg-el" v-if="data.templateContentMedia.overviewImage.url" v-bind:style="{
                'background-image':
                    'url(' +
                    data.templateContentMedia.overviewImage.url +
                    ')',
            }"></span>

        </div>

        <div class="content-side">
            <div class="meta-info">
            </div>
            <h3 class="part-title">
                {{ data.templateContentFields.overviewHeaderTitle }}
            </h3>
        </div>
    </router-link>
</template>

<script>
//import { fetchCmsMedia } from "@streampac.io/chef_sp_1";

export default {
    data() {
        return {
            mediaAsset: null,
            linkedCatEnum: '',
            urlTypeParam: 'Skills'
        };
    },
    inject: ["isAuthenticated"],
    props: {
        observer: null,
        data: {
            type: Object,
            default: () => { },
        },
    },
    created() {
        if (this.data && this.data !== '' && this.data.fields && this.data.fields !== '') {
            //console.log(this.data);
            if (this.data.fields.exercises && this.data.fields.exercises.length > 0) {
                this.linkedCatEnum = this.data.fields.exercises[0].value;
                this.urlTypeParam = 'Exercises';
            } else if (this.data.fields.classes && this.data.fields.classes.length > 0) {
                this.linkedCatEnum = this.data.fields.classes[0].value;
                this.urlTypeParam = 'Classes';
            } else if (this.data.fields.skills && this.data.fields.skills.length > 0) {
                this.linkedCatEnum = this.data.fields.skills[0].value;
                this.urlTypeParam = 'Skills';
            }
        }

        // if (this.data && this.data !== '' && this.data.fields && this.data.fields !== '' && this.data.fields.skills && this.data.fields.skills.length > 0) {
        //     this.linkedCatEnum = this.data.fields.skills[0].value;
        // }

        //allexercises -> training exercises
        //allclasses -> tactical classses

        //console.log(this.data);
    },
    mounted() {
        if (this.observer !== null && this.observer !== undefined) {
            this.observer.observe(this.$el);
        }
    },
    methods: {
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        getFormatDate(val) {
            let d = new Date(val);

            const monthIndex = d.getMonth() + 1;
            const year = d.getFullYear();
            const day = d.getDate();

            return day + "-" + monthIndex + "-" + year;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
